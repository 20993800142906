import React, {FC, useEffect, useState} from 'react'
import OrderReportChart from './OrderReportChart'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import Article from './Article'
import { KTSVG } from 'src/marketConnect/helpers'
import { getUserReportsAsync } from 'src/redux/actions/authActions'
import Loader from 'src/utils/Loader'
import useAppToast from 'src/hooks/useAppToast'
import { GRAPH_DATA } from 'src/app/_interfaces/orderDetail.interface'

const Dashboard: FC = () => {
  const {mainStoreData} = useAppSelector((state) => state.fetchConnectedPlatform)
  const { fetchedUserAccountData } = useAppSelector(state=>state.userAccountData)
  const [loading, setLoading] = useState(true)
  const [statData, setStatData] = useState({orders_sync_errors:0,synced_sku_count:0,total_orders_synced:0,total_sku_count:0 })
  const [filters, setFilters] = useState({from_date:'',to_date:'', platform_id:'',filter_type:''})
  const dispatch = useAppDispatch()
  const [graphData, setGraphData] = useState<GRAPH_DATA[]>([])
  const toast = useAppToast()
  const news = [
    {
      date: '1st Jan',
      title: 'News Title 1',
      description:
        ' Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit',
      link: 'https://example.com/news1',
    },
    {
      date: '2nd Jan',
      title: 'News Title 2',
      description:
        ' Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit eum possimus aliquam repellendus eaque iste modi aperiam dolores cum incidunt, ex, laudantium ea voluptatem doloribus neque temporibus maiores, facere nihil.',
      link: 'https://example.com/news2',
    },
    // {
    //   date: '3rd Jan',
    //   title: 'News Title 3',
    //   description:
    //     ' Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit eum possimus aliquam repellendus eaque iste modi aperiam dolores cum incidunt,',
    //   link: 'https://example.com/news3',
    // },
    // {
    //   date: '4th Jan',
    //   title: 'News Title 4',
    //   description:
    //     ' Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit eum possimus aliquam repellendus ',
    //   link: 'https://example.com/news4',
    // },
    // {
    //   date: '5th Jan',
    //   title: 'News Title 5',
    //   description:
    //     ' Lorem ipsum dolor sit amet, consectetur adipisicing elit. ',
    //   link: 'https://example.com/news5',
    // },
    // {
    //   date: '6th Jan',
    //   title: 'News Title 6',
    //   description:
    //     ' Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit eum possimus aliquam repellendus eaque iste modi aperiam dolores cum incidunt, ex, laudantium ea voluptatem doloribus neque temporibus maiores, facere nihil.',
    //   link: 'https://example.com/news6',
    // },
  ]

  const itemsPerPage = 3
  const [currentPage, setCurrentPage] = useState(1)
  const totalPages = Math.ceil(news.length / itemsPerPage)

  const handleClickNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }

  const handleClickPrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const currentNews = news.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)

  useEffect(() => {
    if(fetchedUserAccountData?.id){
      setLoading(true)
      dispatch(getUserReportsAsync({user_id:fetchedUserAccountData.id, ...filters}))
      .then((res)=>{
        console.log('report data', res.payload)
        if(res.payload?.status_code==200){
          if(res.payload?.data?.users_data){
            setStatData({
              orders_sync_errors:res.payload?.data?.users_data?.orders_sync_errors,
              synced_sku_count:res.payload?.data?.users_data?.synced_sku_count,
              total_orders_synced:res.payload?.data?.users_data?.total_orders_synced,
              total_sku_count:res.payload?.data?.users_data?.total_sku_count
            })
          }

          if(res?.payload?.data?.graph_data){
            setGraphData(res?.payload?.data?.graph_data)
          }
        }
        if(res.payload?.status==403){
          toast.warningToast(res.payload?.data?.detail)
        }
        setLoading(false)
      })
      .catch(()=>{
        setLoading(false)
      })
    }
  }, [filters])

  // if(loading) return <Loader/>
  
  return (
    <div>
      <div className='row'>
        <div className='col-md-8 col-12'>
          {/* stat card section  */}
          <div className='row g-4'>
            <div className='col-xl-3 col-md-6 col-12 col-sm-6 '>
              <div className='card p-4 pb-3 border-0 shadow bg-light-primary bg-hover-light-secondary h-100'>
              <div className='d-flex flex-column justify-content-between h-100 ' >
                <div className='d-inline-flex align-items-center justify-content-between' >
                  <div>
                  <button className='btn btn-link m-0 p-0' >
                    <p className='fs-3 fw-bold mb-0 text-primary' style={{fontFamily:'sans-serif', filter: 'drop-shadow(1px 1px 1px #0002)' }} >{ loading ? <small className='fs-6' >Loading...</small> : statData?.total_sku_count}</p>
                  </button>
                    <p className='h6 mb-0 text-primary fw-bold'>Total SKU's </p>
                  </div>
                  <div>
                  <i className="bi bi-ui-checks-grid text-primary fs-2x fw-bold"></i>
                  </div>
                </div>
                <div className='d-flex' >
                </div>
              </div>
              </div>
            </div>
            <div className='col-xl-3 col-md-6 col-12 col-sm-6 '>
              <div className='card p-4 border-0 shadow bg-light bg-hover-light-secondary h-100'>
              <div className='d-flex flex-column justify-content-between h-100' >
                <div className='d-inline-flex align-items-center justify-content-between' >
                  <div>
                  <button className='btn btn-link m-0 p-0' >
                    <p className='fs-6 fw-bold mb-0 text-secondary text-start' style={{fontFamily:'sans-serif', filter: 'drop-shadow(1px 1px 1px #0002)' }} >{ loading ? <small className='fs-6' >Loading...</small> :  mainStoreData ? 'Connected' : 'Not Connected'}</p>
                  </button>
                    <p className='h6 mb-0 text-secondary fw-bold'>Primary Channel</p>
                  </div>
                  <div>
                    <div className='d-flex align-items-center'>
                      { mainStoreData?.platform_info?.logo_ref ?
                        <img
                        src={mainStoreData?.platform_info?.logo_ref}
                        className='h-30px w-30px ms-md-2'
                        alt='Primary Platform Logo'
                      /> :
                      <i className="bi bi-bag-check-fill text-success fs-2x"></i>                      
                      }
                    </div>
                  </div>
                </div>
                <div className='' >
                </div>
              </div>
              </div>
            </div>
            <div className='col-xl-3 col-md-6 col-12 col-sm-6 '>
              <div className='card p-4 pb-1 border-0 shadow bg-light-success bg-hover-light-secondary h-100'>
              <div className='d-flex flex-column justify-content-between h-100' >
                <div className='d-inline-flex align-items-center justify-content-between' >
                  <div>
                    <button className='btn btn-link m-0 p-0' >
                      <p className='fs-3 fw-bold mb-0 text-success' style={{fontFamily:'sans-serif'}} >{ loading ? <small className='fs-6' >Loading...</small> : statData?.synced_sku_count}</p>
                    </button>
                    <p className='h6 mb-0 text-success fw-bold'>Synced SKU's</p>
                  </div>
                  <div>
                  <i className="bi bi-link-45deg text-success fs-2x fw-bold"></i>
                  </div>
                </div>
                <div className='d-flex' >
                </div>
              </div>
              </div>
            </div>
              <div className='col-xl-3 col-md-6 col-12 col-sm-6 '>
                <div className='card p-4 pb-1 border-0 shadow bg-light-danger bg-hover-light-secondary h-100'>
                  <div className='d-flex flex-column justify-content-between h-100' >
                    <div className='d-inline-flex align-items-center justify-content-between' >
                        <div>
                          <button className='btn btn-link m-0 p-0' >
                            <p className='fs-3 fw-bold mb-0 text-danger' style={{fontFamily:'sans-serif', filter: 'drop-shadow(1px 1px 1px #0002)' }} >{ loading ? <small className='fs-6' >Loading...</small> : statData?.orders_sync_errors}</p>
                          </button>
                          <p className='h6 mb-0 text-danger fw-bold'>Order Sync Errors</p>
                        </div>
                        <div>
                        <i className="bi bi-exclamation-triangle-fill fs-3 text-danger"></i>
                        </div>    
                    </div>
                    <div className='d-flex' >
                    </div>
                  </div>
                </div>
              </div>
          </div>
          {/* stat card section  */}
          <div className='chart-section mt-4'>
           { loading ? <Loader/> : <OrderReportChart className='' filters={filters} setFilters={setFilters} graphData={graphData} />}
          </div>
        </div>
        <div className='col-md-4 col-12'>
          <div className='card h-50 shadow-sm border-0 mb-2'>
            <div className='card-header border-0 bg-white ribbon ribbon-start ribbon-clip' style={{minHeight:'50px'}} >
              <div className='ribbon-label'>
                <p className='fs-5 fw-bold mb-0'>News & Updates</p>
                <span className='ribbon-inner bg-primary'></span>
              </div>
            </div>
            <div className='card-body p-0 overflow-y-scroll'>
              <ul style={{listStyle: 'none'}} className='p-4 mb-0'>
                {currentNews.map((item, index) => (
                  <li key={'newsitem' + index} className='fs-5 '>
                    <Article item={item} />
                    <div className='separator my-1'></div>
                  </li>
                ))}
              </ul>
              <nav>
                <ul className="pagination p-2 justify-content-end" >
                  <li className="page-item">
                    <a className="page-link" href="#" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
                </nav>
            </div>
           
          </div>
          <div className='card h-50 shadow-sm border-0'>
            <div className='card-header border-0 bg-white ribbon ribbon-start ribbon-clip' style={{minHeight:'50px'}} >
              <div className='ribbon-label'>
                {' '}
                <p className='fs-5 fw-bold mb-0'>Setup Guide</p>
                <span className='ribbon-inner bg-primary'></span>
              </div>
            </div>
            <div className='card-body px-2'>
              <ol>
                {[1, 2, 3, 4,].map((item) => {
                  return (
                    <li key={'newsitem' + item} className='fs-5 my-2 mb-0'>
                      This is a sample update / news
                    </li>
                  )
                })}
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
