/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import {useThemeMode} from 'src/marketConnect/partials'
import {getCSS, getCSSVariableValue} from 'src/marketConnect/assets/ts/_utils'
import AppDatePickerRange from '../orders/components/AppDatePickerRange'
import moment from 'moment'
import {useAppSelector} from 'src/redux/storeHooks'
import ReactApexChart from 'react-apexcharts'
import {ApexOptions} from 'apexcharts'
import AppDatePickerWithCustomOptions from '../orders/components/AppDatePickerWithCustomOptions'
import { GRAPH_DATA } from 'src/app/_interfaces/orderDetail.interface'

type Props = {
  className: string
  filters: {
    from_date: string;
    to_date: string;
    platform_id: string;
    filter_type: string;
}
  setFilters: React.Dispatch<React.SetStateAction<{
    from_date: string;
    to_date: string;
    platform_id: string;
    filter_type: string;
}>>
graphData: GRAPH_DATA[]
}

const OrderReportChart: React.FC<Props> = ({className,filters, setFilters, graphData}) => {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-info')
  const lightColor = getCSSVariableValue('--bs-info-light')

  const chartRef = useRef<HTMLDivElement | null>(null)
  const [isDatePickerVisible, setIsDatePickerVisible] = useState<boolean>(false)
  const {fetchedConnectedPlatformData} = useAppSelector((state) => state.fetchConnectedPlatform)
   

  const [dateRange, setDateRange] = useState<string>('This Year') // Default set to "This Year"
  const [chartData, setChartData] = useState<number[]>([])
  const [categories, setCategories] = useState<string[]>([])
  const [maxYValue, setMaxYValue] = useState<number>(0)
  

  const formatDayLabel = (date: string) => moment(date).format('DD')

  // Function to filter data based on the selected date range
  const filterDataByRange = (range: string) => {
    let data: number[] = []
    let categories: string[] = []
    const today = moment()
    
    // switch (range) {
    //   case 'Last 7 Days':
    //     data = [30, 40, 40, 90, 90, 70, 70] // Replace with actual data
    //     categories = Array.from({length: 7}, (_, i) => today.clone().subtract(i, 'days').format('MMM DD')).reverse()
    //     break
    //   case 'Last 30 Days':
    //     data = Array.from({length: 30}, () => Math.floor(Math.random() * 100)) // Mock data
    //     categories = Array.from({length: 30}, (_, i) => today.clone().subtract(i, 'days').format('MMM DD')).reverse()
    //     break
    //   case 'Last 3 Months':
    //     data = Array.from({length: 12}, () => Math.floor(Math.random() * 100)) // Mock week-wise data
    //     categories = Array.from({length: 12}, (_, i) => `Week ${12 - i}`).reverse() // Display week numbers
    //     break
    //   case 'This Year':
    //     data = Array.from({length: 12}, () => Math.floor(Math.random() * 100)) // Mock month-wise data
    //     categories = moment.monthsShort() // Display short month names (Jan, Feb, etc.)
    //     break
    //   default:
    //     // Default to last 12 months
    //     break
    //     data = Array.from({length: 12}, () => Math.floor(Math.random() * 100)) // Mock last 12 months
    //     categories = moment.monthsShort() // Display month names
    // }
    data = Array.from({length: 12}, () => Math.floor(Math.random() * 100)) // Mock last 12 months
    categories = moment.monthsShort() // Display month names
    setChartData(data)
    setCategories(categories)
  }

  // useEffect(() => {
  //   filterDataByRange(dateRange)
  // }, [filters])


  useEffect(() => {
    if (graphData.length > 0) {
      const data = graphData.map(item => item.orders.order_number)
      const days = graphData.map(item => formatDayLabel(item.day))
      setChartData(data)
      setCategories(days)

      const maxOrderNumber = Math.max(...data)
      setMaxYValue(maxOrderNumber)
    }
  }, [graphData])

  const options: ApexOptions = {
    series: [
      {
        name: 'Orders',
        data: chartData,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'line',
      height: 300,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    markers:{
      colors:'#256efd',
      size:8,
      hover:{
        size:10
      }

    },
    stroke: {
      curve: 'straight',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      categories: categories,
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      min:0,
      max: maxYValue+5,
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
        formatter: (value) => Math.ceil(value)+'',
      },
    },
    colors: [lightColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 2,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header bg-white border-bottom border-0   '>
        <h3 className='card-title align-items-start flex-column '>
          <span className='card-label fw-bold fs-4 mb-1'>Order Report</span>

          {/* <span className='text-muted fw-semibold fs-7'>More than 1000 new records</span> */}
        </h3>

        {/* begin::Toolbar */}
        <div className='card-toolbar' data-kt-buttons='true'>
          <div className='channel'>
            <select value={filters.platform_id} className='form-select' onChange={(ev)=>setFilters(pre=>({...pre, platform_id:ev.target.value}))} >
              <option value=''>All Channels</option>
              {fetchedConnectedPlatformData?.map((channel) => {
                if (!channel?.isMainStore) {
                  return <option key={channel?.id+''} value={channel?.id}>{channel?.display_name}</option>
                }
              })}
            </select>
          </div>

          <div className='ms-2'>
            <button
              className='btn fs-6 btn-sm bg-white  border text-dark h-30px align-items-center d-flex justify-content-center '
              onClick={() => setIsDatePickerVisible(!isDatePickerVisible)}
            >
              {filters.from_date && filters.to_date
                ? `${moment(filters.from_date).format('YYYY/MM/DD')}  -  ${moment(
                    filters.to_date
                  ).format('YYYY/MM/DD')}`
                : 'Pick a Date Range'}
            </button>
            {/* <select
            name='dateRange'
            id='dateRange'
            defaultValue={'This Year'}
            className='form-select'
            onChange={(e) => setDateRange(e.target.value)}
          >
            <option value='Last 7 Days'>Last 7 Days</option>
            <option value='Last 30 Days'>Last 30 Days</option>
            <option value='Last 3 Months'>Last 3 Months</option>
            <option value='This Year'>This Year</option>
          </select> */}
            <AppDatePickerWithCustomOptions
              isVisible={isDatePickerVisible}
              handleClose={() => setIsDatePickerVisible(false)}
              onPickRange={({startDate, endDate}) => {
                // Update the selected start and end dates
                  setFilters((pre)=>{
                    return {...pre, from_date:moment(startDate).format('YYYY-MM-DD'), to_date: moment(endDate).format('YYYY-MM-DD')}
                  })
                // setDtRange({
                //   startDt: moment(startDate).format('YYYY-MM-DD'),
                //   endDt: moment(endDate).format('YYYY-MM-DD'),
                // })
              }}
            />
          </div>

          <button className='btn btn-sm btn-warning fw-bold ms-2 h-30px px-4 d-flex align-items-center' onClick={()=>{
            setFilters({
              filter_type:'',
              from_date:'',
              platform_id:"",
              to_date:''
            })
          }} >
            Reset
          </button>
        </div>
        {/* end::Toolbar */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body'>
        {/* begin::Chart */}
        <div ref={chartRef} id='kt_charts_widget_3_chart' style={{height: '350px'}}>
          <ReactApexChart options={options} series={options.series} type='line' height={350} />
        </div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export default OrderReportChart
