import clsx from 'clsx'
import React, {Dispatch, FC, SetStateAction, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import TourStep1 from './components/step-one/TourStep1'
import TourStep2 from './components/step-two/TourStep2'
import PlatformConfiguration from './components/step-three/PlatformConfiguration'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import {
  fetchConnectedPlatformAsync,
  fetchPlatformsAsync,
  retrivePlatformDataAsync,
} from 'src/redux/actions/platformActions'
import Loader from 'src/utils/Loader'
import {KTSVG, toAbsoluteUrl} from 'src/marketConnect/helpers'
import { setInitialPopupDataAsync } from 'src/redux/actions/authActions'

type StepStatus = 'active' | 'inactive' | 'finished'

type TourSteps = {
  stepOne: StepStatus
  stepTwo: StepStatus
  stepThree: StepStatus
}

type TourPopupPropsType = {
  // visible:boolean
  // loading:boolean
  // stepsData:TourSteps
  // selectedPlatformId:any
  // setStepsData: Dispatch<SetStateAction<TourSteps>>
  // setVisible: Dispatch<SetStateAction<boolean>>
  // setSelectedPlatformId: Dispatch<SetStateAction<string | undefined>>

}

// const TourPopup: FC<TourPopupPropsType> = ({loading,visible, stepsData, selectedPlatformId,setStepsData,setVisible,setSelectedPlatformId }) => {
  const TourPopup: FC<TourPopupPropsType> = () => {

  const [loading, setLoading] = useState(true)
  const [visible, setVisible] = useState(false)
  const [selectedPlatformId, setSelectedPlatformId] = useState<string | undefined>(undefined)
  const dispatch = useAppDispatch()
  const notOpenInPaths = ['/handle-app-installation','/handle-oauth-callback-restricted','/platform-setting','/primary-platform-setting','/platform-setting-new']
  const {fetchedConnectedPlatformLoading} = useAppSelector((state) => state.fetchConnectedPlatform)
  const [stepsData, setStepsData] = useState<TourSteps>({
    stepOne: 'active',
    stepTwo: 'inactive',
    stepThree: 'inactive',
  })

  useEffect(() => {
    console.log('current path-->', window.location.pathname);
    if(!notOpenInPaths.includes(window.location.pathname) && !notOpenInPaths.some(item=>(window.location.pathname.startsWith(item))) ){
      (async function () {
        try {
          await dispatch(fetchPlatformsAsync())
          const res = await dispatch(fetchConnectedPlatformAsync())
  
          if (res.payload?.status_code == 200) {
            if (res.payload?.data?.length && res.payload?.data?.length <= 2) {
              setLoading(true)
              const isMainStoreCreated = res.payload?.data?.find((item) => item?.isMainStore == true)
              const isSecondaryStoreCreated = isMainStoreCreated && res.payload?.data?.length == 2
              //checking only main store created
              if (!isMainStoreCreated && !isSecondaryStoreCreated) {
                setVisible(true)
                setLoading(false)
              }
  
              if (isMainStoreCreated && !isSecondaryStoreCreated) {
                setStepsData((pre) => {
                  return {...pre, stepOne: 'finished', stepTwo: 'active'}
                })
                setVisible(true)
                setLoading(false)
              }
  
              //checking secondary store is also created but setting not configured
              if (isMainStoreCreated && isSecondaryStoreCreated) {
                const secondaryStoreSetting = await dispatch(
                  retrivePlatformDataAsync({id: res.payload?.data[1]?.id})
                )
                setSelectedPlatformId(res.payload?.data[1]?.id)
                if (secondaryStoreSetting.payload?.platform_settings) {
                  //it means user has created secondary store and made setting also
                  await dispatch(setInitialPopupDataAsync({isPlatformInitProcessCompleted:true}))
                  setVisible(false)
                  setLoading(false)
                } else {
                  setVisible(true)
                  setStepsData((pre) => {
                    return {stepOne: 'finished', stepTwo: 'finished', stepThree: 'active'}
                  })
                }
                setLoading(false)
              }
            } else if (res.payload?.data?.length > 2) {
              setVisible(false)
              setLoading(false)
            }else{
              setLoading(false)
              setVisible(true)
            }
          }
        } catch (error) {
          setLoading(false)
          setVisible(false)
        }
      })();
    }else{
      // alert("your current path is ====> " + window.location.pathname)
    }
  }, [window.location.pathname])

  return (
    <Modal show={visible} scrollable size='xl' backdropClassName='bg-dark'   >
      {fetchedConnectedPlatformLoading || loading ? (
        <Loader />
      ) : (
        <>
          <Modal.Header className='p-0 m-0 border-0'>
            <div className='w-100 ' >
                <div className='alert bg-light-primary d-flex flex-column flex-sm-row w-100 m-0'>
                  <div className='d-flex align-items-center w-100'>
                      <div
                        className={clsx(
                          'd-flex align-items-center',
                          stepsData.stepOne == 'active'
                            ? 'text-primary'
                            : stepsData.stepOne == 'finished'
                            ? 'text-success'
                            : ''
                        )}
                      >
                        {
                          stepsData.stepOne==='finished'? 
                          <i className={ clsx( "bi bi-check-circle-fill fs-2x me-2")} style={{color:'#07bc0c'}} ></i>
                          : <i className={ clsx( "bi bi-1-circle-fill fs-2x me-2" , stepsData.stepOne==='active'? "text-primary" : "text-secondary")} ></i>
                          
                        }
                        {/* <KTSVG path="media/icons/duotune/coding/cod008.svg" className={ stepsData.stepOne==='finished'? "svg-icon-success svg-icon-2hx" : "svg-icon-primary svg-icon-2hx"} /> */}
                        <small className='fs-6 fw-bold lh-1'>Connect Primary Platform</small>
                        
                      </div>
                    
                    <div className='separator w-25 border-2 border-light-secondary me-5'></div>
                      <div
                        className={clsx(
                          'd-flex align-items-center',
                          stepsData.stepTwo == 'active'
                            ? 'text-primary'
                            : stepsData.stepTwo == 'finished'
                            ? 'text-success'
                            : 'text-secondary'
                        )}
                      >
                        {
                          stepsData.stepTwo=='finished' ?
                          <i className={ clsx( "bi bi-check-circle-fill fs-2x me-2")} style={{color:'#07bc0c'}} ></i>
                          :
                          <i className={ clsx( "bi bi-2-circle-fill fs-2x me-2" , stepsData.stepTwo=='inactive' ? "text-secondary" : stepsData.stepTwo==='active' ? "text-primary" : "text-secondary" )} ></i>
                        }
                        {/* <KTSVG path="media/icons/duotune/coding/cod007.svg" className={ stepsData.stepTwo=='inactive' ? "svg-icon-muted svg-icon-2hx" : stepsData.stepTwo==='active' ? "svg-icon-primary svg-icon-2hx" : "svg-icon-success svg-icon-2hx"  }  /> */}
                        <small className='fs-6 fw-bold lh-1'>Connect Secondary Platform</small>
                        
                      </div>
                     
                    <div className='separator w-25 border-2 border-light-secondary me-5'></div>
                      <div
                        className={clsx(
                          'd-flex align-items-center',
                          stepsData.stepThree == 'active'
                            ? 'text-primary'
                            : stepsData.stepThree == 'finished'
                            ? 'text-success'
                            : 'text-secondary'
                        )}
                      >
                        {
                          stepsData.stepThree=='finished' ?
                          <i className={ clsx( "bi bi-check-circle-fill fs-2x me-2")} style={{color:'#07bc0c'}} ></i>
                          :
                        <i className={ clsx( "bi bi-3-circle-fill fs-2x me-2" , stepsData.stepThree=='inactive' ? "text-muted" : stepsData.stepThree==='active' ? "text-primary" : "text-secondary" )} ></i>
                        }
                        {/* <KTSVG path="media/icons/duotune/coding/cod001.svg" className={ stepsData.stepThree=='inactive' ? "svg-icon-muted svg-icon-2hx" : stepsData.stepThree==='active' ? "svg-icon-primary svg-icon-2hx" : "svg-icon-success svg-icon-2hx"  } /> */}
                        <small className='fs-6 fw-bold lh-1'>Configure Secondary Platform</small>
                        
                      </div>
                    
                  </div>
                </div>
              
            </div>
          </Modal.Header>
          <Modal.Body>
            {stepsData.stepOne === 'active' ? (
              <TourStep1
                setStepsData={setStepsData}
                setSelectedPlatformId={setSelectedPlatformId}
              />
            ) : null}

            {stepsData.stepTwo === 'active' ? (
              <TourStep2
                setStepsData={setStepsData}
                setSelectedPlatformId={setSelectedPlatformId}
              />
            ) : null}

            {stepsData.stepThree === 'active' ? (
              <PlatformConfiguration selectedPlatformId={selectedPlatformId} afterSettingCB={()=>{
                setStepsData({
                  stepOne:'finished',
                  stepTwo:'finished',
                  stepThree:'finished'
                })
                setVisible(false)
              }} />
            ) : null}
            <section></section>
          </Modal.Body>
          {/* <Modal.Footer>
          <div>
            <button
              className='btn w-100px btn-primary shadow fs-5 fw-bold '
              onClick={() => {
                if (stepsData.stepOne == 'active') {
                  setStepsData((pre) => {
                    return {...pre, stepOne: 'finished', stepTwo: 'active'}
                  })
                } else if (stepsData.stepTwo == 'active') {
                  setStepsData((pre) => {
                    return {...pre, stepTwo: 'finished', stepThree: 'active'}
                  })
                } else if (stepsData.stepThree == 'active') {
                  setStepsData((pre) => {
                    return {...pre, stepThree: 'finished'}
                  })
                  setVisible(false)
                }
              }}
            >
              {stepsData.stepThree === 'active' ? 'Finish' : 'Next'}
            </button>
          </div>
        </Modal.Footer>
       */}
        </>
      )}
    </Modal>
  )
}

export default TourPopup
