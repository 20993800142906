import React, { FC, useState } from 'react';
// import './PlatformCard.css'

import { Platform, PlatformDataType } from 'src/interfaces/Platform.interface';
import ConnectPlatformModal from './ConnectPlatformModal';
import { useAppDispatch, useAppSelector } from 'src/redux/storeHooks';
import { useNavigate } from 'react-router-dom';
import useAppToast from 'src/hooks/useAppToast';
import { connectPlatformAsync, fetchConnectedPlatformAsync } from 'src/redux/actions/platformActions';

type StepStatus = 'active' | 'inactive' | 'finished'

type TourSteps = {
    stepOne:StepStatus
    stepTwo:StepStatus
    stepThree:StepStatus
}

type PlatformCardPropType = {
  data: Platform;
  setSelectedPlatformId: React.Dispatch<React.SetStateAction<string | undefined>>
  onClickConnectTop: React.Dispatch<React.SetStateAction<{
    platformId: number | string | null;
    isConnecting: boolean;
}>>
  setStepsData: React.Dispatch<React.SetStateAction<TourSteps>>
};

const PlatformCard: FC<PlatformCardPropType> = ({ data,setStepsData, onClickConnectTop, setSelectedPlatformId }) => {

  const {fetchedConnectedPlatformData, fetchedConnectedPlatformLoading} = useAppSelector(
    (state) => state.fetchConnectedPlatform
  )
  const {shopifyConnectFirstTimeMessageShow, firstTimeMessage} = useAppSelector(
    (state) => state.auth
  )
  const {fetchedUserAccountData} = useAppSelector((state) => state.userAccountData)
  const {platformData} = useAppSelector((state) => state.platform)
  const {connectedPlatformData} = useAppSelector((state) => state.connectedPlatform)
  const [showConnectPlatformModal, setShowConnectPlatformModal] = useState<boolean>(false)
  const [selectedPlatform, setSelectedPlatform] = useState<Platform | null>(null)
  const [ecommercePlatform, setEcommercePlatform] = useState<any>([])
  const [showSettingModal, setShowSettingModal] = useState<boolean>(false)
  const [modalData, setModalData] = useState<PlatformDataType>()
  const {successToast, warningToast, errorToast} = useAppToast()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const onClickConnect = (platform: Platform) => {
    console.log('paltform', platform)
    setSelectedPlatform(platform)
    setShowConnectPlatformModal(true)
  }

  const handleCloseModal = () => {
    setShowConnectPlatformModal(false)
    setSelectedPlatform(null) // Reset selected platform
    onClickConnectTop({platformId:null, isConnecting:false })
  }

  const handleDataReceived = (dataCollector: any, friendlyName: string) => {
    console.log('Credentials taken from input:', dataCollector)
    // const email = localStorage.getItem('userid') // Assuming 'userid' is the key you used to store the email
    setShowSettingModal(true)
    if(dataCollector['friendlyName']==''){
      warningToast("Friendly name is required")
      return 
    }
    if (selectedPlatform) {
      let isMainStore = fetchedConnectedPlatformData.length === 0
      const payload = {
        name: selectedPlatform.name,
        type: selectedPlatform.type,
        code: selectedPlatform.name,
        display_name: friendlyName,
        platform_info: selectedPlatform.id,
        credentials: {
          identifier: dataCollector.find((item: any) => item.name === 'identifier')?.value || null,
          client_id: dataCollector.find((item: any) => item.name === 'client_id')?.value || null,
          client_secret:
            dataCollector.find((item: any) => item.name === 'client_secret')?.value || null,
          token_id: dataCollector.find((item: any) => item.name === 'token_id')?.value || null,
          token_secret:
            dataCollector.find((item: any) => item.name === 'token_secret')?.value || null,
          refresh_token: null,
          sftp_hostname:
            dataCollector.find((item: any) => item.name === 'sftp_hostname')?.value || null,
          sftp_username:
            dataCollector.find((item: any) => item.name === 'sftp_username')?.value || null,
          sftp_password:
            dataCollector.find((item: any) => item.name === 'sftp_password')?.value || null,
            country : dataCollector.find((item: any) => item.name === 'country')?.value || '',
        },
        isConnected: true,
        isActive: true,
        isMainStore: isMainStore,
        isOrderSyncProcessing: false,
        isNewOrderSyncProcessing: false,
        isOrderCreateProcessing: false,
      }
      console.log('payload: ', payload)
      dispatch(connectPlatformAsync(payload))
        .then((response) => {
          dispatch(fetchConnectedPlatformAsync())
          if (response.meta.requestStatus === 'fulfilled') {
            console.log('response after connecting platform ', response)
            const statusCode = response.payload.status_code
            const platformName = payload.name // Assuming name is the property containing the platform name
            // setModalData(response.payload.data)
            if (response.payload?.data?.isMainStore) {
              // navigate(`/primary-platform-setting/${response.payload?.data?.id}`)
              setStepsData((pre)=>{
                return {...pre, stepOne:'finished', stepTwo:'active' }
              })
            } else {
              // navigate(`/platform-setting/${response.payload?.data?.id}`)
              setSelectedPlatformId(response.payload?.data?.id)
              setStepsData((pre)=>{
                return {...pre, stepOne:'finished', stepTwo:'finished', stepThree:'active' }
              })
            }
            if (response?.payload?.status_code == 201) {
              successToast(`${selectedPlatform.display_name} Channel Connected Successfully`)
              setShowSettingModal(false)
              return
            } else {
              return errorToast(
                `Failed to connect platform ${platformName}. Status code: ${statusCode}`
              )
            }
          } else {
            console.error('Connection failed:', response.payload)
            return errorToast('Failed to connect platform. Please try again.')
          }
        })
        .catch((error) => {
          console.error('Error connecting platform:', error)
          errorToast('An error occurred while connecting the platform. Please try again.')
        })
    }
  }

  return (
    <div className={ showConnectPlatformModal ? 'col-lg-6 col-12 ' : 'col-sm-6 col-lg-6 col-xl-4 col-md-6 m-0 mt-3'}>
      <div className='card position-relative mb-md-3 mb-2 mb-xl-8 cursor-pointer h-100'>
          {showConnectPlatformModal ? 
          <div className='d-flex justify-content-end px-4' >
            <a
              target='_blank'
              href='https://commercium.tawk.help/'
              className='btn btn-link text-primary fs-6 fw-bold '
            >
              Need Help ?
            </a>
        </div> : null }
        <div className='card-body align-items-center p-3 px-7 '>
          {/* Platform information */}
          <div className='d-flex row'>
            <div className='col-xxl-9 col-md-8 col-9'>
              <div className='d-flex flex-column flex-grow-1 py-2 py-lg-9 me-2 w-100'>
                <div className=' fw-bolder fs-4 fs-md-2 mb-2'>{data.display_name}</div>
              </div>
            </div>
            <div className='col-xxl-3 col-md-4 col-3 d-flex justify-content-end'>
              <div className='w-100px h-50px' >
                <img src={data.logo_ref}
                 alt={data.display_name}
                  style={{ width: '100%',height:'100%', objectFit:'contain' }} />
              </div>
            </div>
          </div>

          

          {/* Connect button */}
          { showConnectPlatformModal ? 
            <ConnectPlatformModal
            isVisible={showConnectPlatformModal}
            closeModal={handleCloseModal}
            data={selectedPlatform}
            onDataReceived={handleDataReceived} // Pass the data receiving function
          /> : null }
          
        </div>
       { !showConnectPlatformModal && <div className='d-flex justify-content-end my-4'>
            <button  className="btn btn-primary btn-sm me-4" onClick={()=>{ onClickConnect(data); onClickConnectTop({platformId:data.id, isConnecting:true }) }}>
              Connect
            </button>
          </div>}
      </div>
    </div>
  );
};

export default PlatformCard;
// PlatformCard.tsx

// import React, { FC } from 'react';
// import { Platform } from 'src/interfaces/Platform.interface';

// import './PlatformCard.css'; // Import the styles for PlatformCard

// type PlatformCardPropType = {
//   data: Platform;
//   onClickConnect: () => void;
// };

// const PlatformCard: FC<PlatformCardPropType> = ({ data, onClickConnect }) => {
//   return (
//     <div className='platform-card col-sm-6 col-lg-4 col-xl-3 m-0'>
//       <div className='card position-relative card-xl-stretch mb-5 mb-xl-8 bg-light-info bg-hover-secondary cursor-pointer'>
//         <div className='card-body align-items-center p-7 pb-2'>
//           <div className='platform-info d-flex row'>
//             <div className='col-xxl-8 col-lg-7 col-6'>
//               <div className='info-container d-flex flex-column flex-grow-1 py-2 py-lg-9 me-2 w-100'>
//                 <div className='platform-name fw-bolder fs-4 mb-2'>{data.display_name}</div>
//               </div>
//             </div>
//             <div className='col-xxl-4 col-lg-5 col-6 d-flex align-self-center'>
//               <div className='platform-logo'>
//                 <img src={data.logo_ref} alt={data.display_name} className='img-fluid' />
//               </div>
//             </div>
//           </div>

//           <div className='connect-button d-flex justify-content-end my-4'>
//             <button className='btn btn-primary' onClick={onClickConnect}>
//               Connect
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PlatformCard;
