import React, {useEffect, useState} from 'react'
import {KTSVG} from 'src/marketConnect/helpers'
import AlertModal from 'src/utils/modals/AlertModal'
import ConnectPlatformStrip from './components/ConnectPlatformStrip'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import Spinner from 'react-bootstrap/Spinner'

import {
  createPlatformSettingAsync,
  deletePlatformDataAsync,
  fetchConnectedPlatformAsync,
  retrivePlatformDataAsync,
  shopifyappUninstallAsync,
} from 'src/redux/actions/platformActions'
import {PlatformDataType, PlatformSettingsDataType} from 'src/interfaces/Platform.interface'
import {toast} from 'react-toastify'
import ConnectPlatformStrip1 from './components/ConnectPlatformStrip1'
import {Link, Navigate, useNavigate, useParams, useSearchParams} from 'react-router-dom'
import { isActiveBoolean } from 'src/redux/actions/changeisActiveAction'
import { getApproveSubscriptionAsync, getUserPlanAsync } from 'src/redux/actions/pricingActions'
import useAppToast from 'src/hooks/useAppToast'
import CommonAlertModal from 'src/utils/modals/CommonAlertModal'
import { setShopifyConnectFirstTimeMessageShow } from 'src/redux/slices/authSlice'


const ConnectedPlatforms = () => {
  const navigate = useNavigate();
  const [showAlertModal, setShowAlertModal] = useState<boolean>(false)
  const [showSettingModal, setShowSettingModal] = useState<boolean>(false)
  const [selectedPlatform, setSelectedPlatform] = useState<PlatformDataType>()
  const [selectedPlatformDelete, setSelectedPlatformDelete] = useState<PlatformDataType>()

  const [deletionCompleted, setDeletionCompleted] = useState<boolean>(false) // New state variable
  const {fetchedConnectedPlatformLoading} = useAppSelector((state) => state.fetchConnectedPlatform)
  const fetchedConnectedPlatformData = useAppSelector(
    (state) => state.fetchConnectedPlatform.fetchedConnectedPlatformData
    
  )
  const { userCurrentSubscription, requestSubscriptionLoading} = useAppSelector((state) => state.pricing)
  const {connectedPlatformData,platformSettingModalData } = useAppSelector((state) => state.connectedPlatform)
  const {successToast, errorToast} = useAppToast()
  const {selectedPlatformId} = useParams()
  const [searchParams, setSearchParams] = useSearchParams();
  const charge_id= searchParams.get("charge_id") 
  const contract_id = searchParams.get('contract_id')
  const billing_intent_id = searchParams.get('billing_intent_id')
  const success = searchParams.get('success')
  const userId = searchParams.get('user_id')

  const [isVisibleAlertModel, setIsVisibleAlertModel] =useState<boolean>(false)
  const { fetchedUserAccountData } = useAppSelector(state=>state.userAccountData)

  useEffect(() => {
    if(window.location.search){
      let queryItems = window.location.search.slice(1,window.location.search.length).split('&')
      let params = {}
      for(let ser of queryItems){
        let keyval = ser.split('=')
        params[keyval[0]] = keyval[1]
      }
      params['platform_id'] = selectedPlatformId ?? 'WOOCOMMERCE' 
      dispatch(getApproveSubscriptionAsync({...params}))
      .then((response)=>{
        if (response?.payload?.status_code === 201 && response?.payload?.data) {
          successToast(response?.payload?.message)
          dispatch(getUserPlanAsync()).then((response) => {
            // console.log('list of  userplan', response)
            setIsVisibleAlertModel(true)
          })
       }else{
        errorToast(response?.payload?.massage)
        dispatch(getUserPlanAsync()).then((response) => {
          // console.log('list of  userplan', response)
        })
        setIsVisibleAlertModel(true)
       }
      })  // Dispatch the action with charge_id
    }
    
    // if ( contract_id && billing_intent_id) {
    //   let queryData = {
    //     contract_id:contract_id, 
    //     billing_intent_id: billing_intent_id,
    //     platform_id:'',
    //     charge_id:'' 
    //   }

    //   if(selectedPlatform){
    //     queryData['platform_id'] = selectedPlatformId+''
    //   }
    //   if(charge_id){
    //     queryData['charge_id'] = charge_id
    //   }
    //   // dispatch(getApproveSubscriptionAsync({...queryData}))
    //   // .then((response)=>{
    //   //   if (response?.payload?.status_code === 201 && response?.payload?.data) {
    //   //     successToast(response?.payload?.message)
    //   //     dispatch(getUserPlanAsync()).then((response) => {
    //   //       console.log('list of  userplan', response)
    //   //     })
    //   //  }else{
    //   //   errorToast(response?.payload?.massage)
    //   //   dispatch(getUserPlanAsync()).then((response) => {
    //   //     console.log('list of  userplan', response)
    //   //   })
    //   //  }
    //   // })  // Dispatch the action with charge_id
    //   // setIsVisibleAlertModel(true)
    // }
  }, [charge_id])

  console.log('selectedPlatform', selectedPlatform)
  const dispatch = useAppDispatch()

  const onDeleteStripe = (platform: PlatformDataType) => {
    console.log('data platform to delete', platform)
    setSelectedPlatformDelete(platform)
    setShowAlertModal(true)
  }


  // useEffect(() => {
  //   if (fetchedConnectedPlatformData && !fetchedConnectedPlatformLoading && deletionCompleted) {
  //     // Check if deletion is completed and loading is false
  //     dispatch(fetchConnectedPlatformAsync())
  //     setDeletionCompleted(false) // Reset deletionCompleted state
  //   }
  // }, []) // Dependency array includes deletionCompleted

  useEffect(() => {
    dispatch(fetchConnectedPlatformAsync())
  }, [])

  // useEffect(() => {
  //  if(platformSettingModalData){
  //   setSelectedPlatform(platformSettingModalData)
  //   setShowSettingModal(true)
  //  }
  // }, [platformSettingModalData])



  const onDeleteConfirmed = async () => {
    try {
      if (selectedPlatformDelete && selectedPlatformDelete.id !== undefined) {

        //uninstall shopify 
        if(fetchedUserAccountData?.source==="SHOPIFY"){
          dispatch(shopifyappUninstallAsync({id:selectedPlatformDelete?.id})).then((response)=>{
            toast.success(response?.payload?.massage) 
            dispatch(fetchConnectedPlatformAsync())
          })
        }else{
          dispatch(deletePlatformDataAsync({id: selectedPlatformDelete?.id})).then(() => {
            dispatch(fetchConnectedPlatformAsync())
            toast.success('Platform deleted successfully!', {
              autoClose: 3000,
            })
          })

        }
        
        setDeletionCompleted(true) // Update deletionCompleted state
      } else {
        console.error('Platform id is undefined.')
      }
    } catch (error) {
      console.error('Error deleting platform:', error)
    } finally {
      setShowAlertModal(false)
    }
  }

  return (
    <>
      <div>
        <div id='test1' className='p-4 pt-0 rounded'>
          <div className='d-flex justify-content-end'>
            <Link to='/platforms' className='btn btn-primary btn-sm' style={{fontSize: '14px'}}>
              Add new channel
            </Link>
          </div>
        </div>
      </div>

      {fetchedConnectedPlatformLoading || requestSubscriptionLoading ? (
        <div className='d-flex align-items-center justify-content-center h-100'>
          <h1>
            <Spinner animation='border' variant='primary' />
          </h1>
        </div>
      ) : (
        <div className='py-4 pt-0'>
          {fetchedConnectedPlatformData?.length > 0 ?
            fetchedConnectedPlatformData.map((platform: any, index) => {
              if (platform.isMainStore === true) {
                return (
                  <ConnectPlatformStrip1
                    key={platform.id}
                    platform={platform}
                    onDelete={() => onDeleteStripe(platform)}
                  />
                )
              } else {
                return (
                  <ConnectPlatformStrip
                    key={platform.id}
                    platform={platform}
                    onDelete={() => onDeleteStripe(platform)}
                  />
                )
              }
            })
          : 
          <div className="alert bg-light-primary d-flex align-items-center p-5 shadow-sm">
            <span className="svg-icon svg-icon-2hx svg-icon-primary me-8">
              <KTSVG path="media/icons/duotune/communication/com009.svg" className="svg-icon-muted svg-icon-3hx" />
            </span>
          
            <div className="d-flex flex-column">
              <h5 className="mb-1">No Platform is connected yet </h5>
              <span>Please connect a platform. Click here to connect <Link to={'/dashboard'} > <KTSVG path="media/icons/duotune/general/gen016.svg" className="svg-icon-primary svg-icon-hx" /></Link>  </span>
            </div>
          </div>
          }
        </div>
      )}


      <AlertModal
        isVisible={showAlertModal}
        closeModal={() => setShowAlertModal(false)}
        onDeleteConfirmed={onDeleteConfirmed}
        selectedPlatformDelete={selectedPlatformDelete}
      />

      <CommonAlertModal
          title = { (success && userId) ? "Woo Commerce is successfully connected" :  userCurrentSubscription && userCurrentSubscription?.request_type=='PLAN_SUBSCRIBE' ? `You have successfully subscribed to the ${userCurrentSubscription?.billing_plan?.display_name} plan. You can now proceed further.` : `You have been ${userCurrentSubscription?.isUpgraded ? 'upgraded' : 'downgraded' } to ${userCurrentSubscription?.billing_plan?.display_name}. Your request to change current plan is completed. we have received the payment, Your new plan is active.` 
            // : "You have successfully subscribed to the plan. You can now proceed further."
           }
          subtitle=''
          onSuccess={() => {
            setIsVisibleAlertModel(false)
            if(fetchedUserAccountData?.source=='WOOCOMMERCE'){
              if(!fetchedConnectedPlatformData?.length){
                dispatch(setShopifyConnectFirstTimeMessageShow({show:true,message:'Go ahead & Connect Woocommerce as your Primary store'}))
              }
              navigate('/dashboard')
            }
          }}
          onCancel={() => {
            setIsVisibleAlertModel(false)
          
          }}
          cancelBtnTitle='Close'
          isVisible={isVisibleAlertModel}
          isTermsVisible={false}
          setIsVisible={() => {}}
          showCloseButton={false}
          iconPath='/media/icons/duotune/general/gen043.svg'
          iconClass="svg-icon-success svg-icon-4hx"
          successBtnClasses='btn btn-sm btn-success  m-2'
          successBtnTitle='Ok'
        />

     
    </>
  )
}

export default ConnectedPlatforms
