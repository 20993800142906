import React, {FC, useState} from 'react'
import {Modal} from 'react-bootstrap'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import {Calendar, createStaticRanges, DateRangePicker, defaultStaticRanges, InputRange, StaticRange} from 'react-date-range'
import moment from 'moment'
import { addDays, endOfMonth, startOfMonth, subDays, subMonths } from 'date-fns'

type SelectionRange = {
    startDate: string,
    endDate: string,
  }

type AppDateFilterProps = {
  isVisible: boolean
  handleClose: () => void
  onPickRange:(range:SelectionRange)=>void
  resetDateFilter?:()=>void
}

const AppDatePickerWithCustomOptions: FC<AppDateFilterProps> = ({ handleClose, isVisible, onPickRange }) => {
  const [selectedRange, setSelectedRange] = useState([{
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  }])

  const staticRanges = createStaticRanges([
    {
      label: 'Today',
      range: () => ({
        startDate: new Date(),
        endDate: new Date(),
      }),
    },
    {
      label: 'Last 7 Days',
      range: () => ({
        startDate: addDays(new Date(), -7),
        endDate: new Date(),
      }),
    },
    {
      label: 'Last 30 Days',
      range: () => ({
        startDate: addDays(new Date(), -30),
        endDate: new Date(),
      }),
    },
    {
      label: 'Last Month',
      range: () => ({
        startDate: startOfMonth(subMonths(new Date(), 1)),
        endDate: endOfMonth(subMonths(new Date(), 1)),
      }),
    },
    {
      label: 'Last 3 Months',
      range: () => ({
        startDate: startOfMonth(subMonths(new Date(), 3)),
        endDate: endOfMonth(subMonths(new Date(), 1)),
      }),
    },
  ]);

  const handleSelect = (ranges) => {
    const { startDate, endDate } = ranges.selection;
    const differenceInDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));

    if (differenceInDays == 30) {
      setSelectedRange([ranges.selection]);
    } else {
      alert('Please select a range within 30 days.');
    }
  };

  return (
    <Modal backdrop='static' centered show={isVisible} className='w-100' >
      <DateRangePicker 
        date={new Date()} 
        minDate={subDays(new Date(), 44)}
        ranges={selectedRange}
        maxDate={new Date()}
        showPreview={true}
        moveRangeOnFirstSelection={false}
        // months={2}
        // direction='horizontal'
        staticRanges={[...staticRanges]}
        inputRanges={[]}
        className='bg-white w-100'
        rangeColors={['#0d6efd', '#3ecf8e', '#fed14c']}
        classNames={{
            selected:'bg-info',
            daySelected:'bg-info',
            calendarWrapper:'overflow-x-scroll w-sm-100'
        }}
        onChange={(ev:any)=>{
            console.log('ev', ev)
            setSelectedRange([ev.selection])
        }}
      />
      {/* <small className='text-danger fw-bold ps-4' >Custom date can be select for 30 days only</small> */}
      <div className='d-flex justify-content-end p-2 bg-white'>
        <button className='btn btn-text btn-sm me-4' onClick={()=>{
            handleClose()
        }} >Close</button>
        <button
          className='btn btn-sm btn-primary'
          onClick={() => {
            onPickRange({
              startDate: moment(selectedRange[0].startDate)
                .utc(true)
                .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
              endDate: moment(selectedRange[0].endDate)
                .utc(true)
                .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
            })
            handleClose()
          }}
        >
          Apply
        </button>
      </div>
    </Modal>
  )
}

export default AppDatePickerWithCustomOptions